import * as React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ContentWrapper from "../../components/wrappers/contentWrapper"
import ContentPart from "../../components/services/template/content"
import FireSafetyPlansHero from "../../components/heros/fireSafetyPlansHero"

const TitleText = `
	Fire Safety Plans
`
const FireSafetyPlansPage = () => (
	<Layout>
		<Seo title={TitleText} description="Professional planning prepares your protection." />

		<FireSafetyPlansHero />
		<ContentWrapper>
			<ContentPart>
				<p>Professional planning prepares your protection.</p>
				<p>Property owners and managers have a legal responsibility to ensure structural safety for the occupants of their buildings. Meaning a solid safety plan is critical to minimize potential damage when emergencies happen.</p>
				<p>We create fire safety plans for our clients, design plans for owners, managers, contractors, and developers of all building types in the private and public sectors.</p>
				<p>Our plans are customized for each site to meet all applicable fire codes and safety By-Laws.</p>
				<p>Relentless Fire and Safety provides services that complement our Fire Safety Plans</p>
				<ul>
					<li>fire safety training for management and staff</li>
					<li>test fire panel monitors</li>
					<li>fire extinguisher stations</li>
					<li>signage for evacuation instructions, emergency procedures, and fire code information.</li>
				</ul>
				<h2>What do you get in a Fire Safety Plan?</h2>
				A well-designed fire safety plan provides both fine details and general emergency procedures customized to your building. We craft your custom plan in a way that communicates the necessary information with ease.
				Key aspects include:
				<ul>
					<li>fire hazard control methods</li>
					<li>floor and site schematics</li>
					<li>equipment maintenance schedule</li>
					<li>contact details for building and human resources</li>
					<li>emergency procedures and backup plans for occupants</li>
					<li>fire drill procedures and reports</li>
					<li>fire safety building audit</li>
					<li>local fire department contact info</li>
				</ul>
				<h2>Fire Safety Plan Preparation</h2>
				<p>During your consultation, we will tour the building to observe and report your fire and safety equipment. As well, compile other important information on your site.</p>
				<p>Once we have the necessary details, we will write text and draft the required imagery required by local By-Laws. We then provide the draft for your review, make any necessary changes, and submit it to the fire department. Once approved by authorities, we provide you with the final design along with the required signage and install fire extinguishers at your request.</p>
				<p><Link to="/contact" title="fire safety services contact">Contact Relentless Fire and Safety to book your Fire Safety Plan consultation.</Link></p>
			</ContentPart>
		</ContentWrapper>
	</Layout>
)

export default FireSafetyPlansPage